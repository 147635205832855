import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const NewsTemplate = ({ data }) => {

  let setImg = null;

  if (data.strapiArticle.img !== null) {
    setImg = getImage(data.strapiArticle.img.localFile);
  }

  return (
    <Layout>
      <div className="container mt-60">
        <h1>{data.strapiArticle.title}</h1>
        <p className="date_pub">
          Дата публикации:
          {new Date(data.strapiArticle.publishedAt).toLocaleDateString()}
        </p>
        <div
          className="one_news"
          dangerouslySetInnerHTML={{
            __html: data.strapiArticle.description.data.description,
          }}
        />
        <div>
          {setImg !== null ? (
            <GatsbyImage
              placeholder="#fff"
              image={setImg}
              alt={data.strapiArticle.title}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  );
};

export default NewsTemplate;

export const newsQuery = graphql`
  query SingleNewsQuery($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      publishedAt
      img {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      description {
        data {
          description
        }
      }
    }
  }
`;
